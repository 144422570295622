import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import PhotoGalleria from './PhotoGalleria'
import './utils.css'

export default function AccordionGallers(props) {
  return (
    <div className="card">
      <Accordion>
        <AccordionTab header={props.lang[6]}>
          <PhotoGalleria
            galleria={props.gall.galleria[0]}
            photoIcon={props.gall.photoIcon[0]}
          />
        </AccordionTab>
        <AccordionTab header={props.lang[7]}>
          <PhotoGalleria
            galleria={props.gall.galleria[1]}
            photoIcon={props.gall.photoIcon[1]}
          />
        </AccordionTab>
      </Accordion>
    </div>
  )
}
