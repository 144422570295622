import './Book.css'
import LongContent from '../../../utils/LongContent'
import { galleriaBooks } from '../../../constants/ImportPhoto'

export default function Books() {
  return (
    <div className="Book-header">
      <LongContent gall={galleriaBooks}/>
    </div>
  )
}

