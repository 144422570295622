import './Photo.css'
import AccordionGallers from '../../../utils/AccordionGallers'
import { galleriaPhoto } from '../../../constants/ImportPhoto'

const Photo = (props) => {
  return (
    <div className="Photo-header">
      <AccordionGallers gall={galleriaPhoto} lang={props.lang} />
      {/* <PhotoGalleria gal01={galleria01} gal02={galleria02} /> */}
    </div>
  )
}

export default Photo
