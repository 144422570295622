import seria2022 from '../assets/Series/2022seria.jpg'
import seria2023 from '../assets/Series/2023seria.jpg'
import seria2017 from '../assets/Series/2017seria.jpg'
import seria2015 from '../assets/Series/2015seria.jpg'
import Сover2016 from '../assets/Series/Сover2016.jpg'
import Сover2017 from '../assets/Series/Сover2017.jpg'
import Сover2018 from '../assets/Series/Сover2018.jpg'
import Сover2019 from '../assets/Series/Сover2019.jpg'
  // header: ['Reportage', 'Artistic', 'Brest 2017'],
export const languages = [
  'VIKTAR VOLSKI',
  'Wedding',
  'Series',
  'Photo',
  'Books',
  'Contact',
  'Reportage',
  'Artistic',
  'Services',
  'Family',
  'Сlub',
  'Object',
  'Food',
  'Other',
]

export const cities = [
  {
    name: 'EN',
    code: [
      'VIKTAR VOLSKI',
      'Wedding',
      'Series',
      'Photo',
      'Books',
      'Contact',
      'Reportage',
      'Artistic',
      'Services',
      'Family',
      'Club',
      'Object',
      'Food',
      'Other',
    ],
  },
  {
    name: 'PL',
    code: [
      'VIKTAR VOLSKI',
      'Ślub',
      'Seria',
      'Zdjęcie',
      'Książki',
      'Łączność',
      'Reportaż',
      'Inscenizacja',
      'Usługi',
      'Rodzina',
      'Klub',
      'Obiekt',
      'Żywność',
      'Inny',
    ],
  },
  {
    name: 'RU',
    code: [
      'VIKTAR VOLSKI',
      'Свадьба',
      'Серии',
      'Фото',
      'Книги',
      'Контакты',
      'Репортаж',
      'Постановка',
      'Услуги',
      'Семья',
      'Клуб',
      'Объект',
      'Еда',
      'Другой',
    ],
  },
]

// const path = 'natasmart.com'
const path = 'volsvik.com'
const pathSeries = `https://${path}/photography/Series/`
const pathGalleris = `https://${path}/photography/Galleris/`
const pathBooks = `'https://${path}/photography/Books/`
const pathClub = `https://${path}/photography/Club/`

export const galleriaClub1000 = []
export const galleriaClub170 = []
for (let i = 0; i < 62; i++) {
  if (i < 9) {
    galleriaClub1000.push(`${pathClub}1000/Club-0000${i + 1}.jpg`)
    galleriaClub170.push(`${pathClub}170/Club-0000${i + 1}.jpg`)
  } else {
    galleriaClub1000.push(`${pathClub}1000/Club-000${i + 1}.jpg`)
    galleriaClub170.push(`${pathClub}170/Club-000${i + 1}.jpg`)
  }
}

export const galleriaBook2016 = []
for (let i = 0; i < 25; i++) {
  if (i < 9) {
    galleriaBook2016.push(`${pathBooks}2016/Book2016-0000${i + 1}.jpg`)
  } else {
    galleriaBook2016.push(`${pathBooks}2016/Book2016-000${i + 1}.jpg`)
  }
}

export const galleriaBook2017 = []
for (let i = 0; i < 22; i++) {
  if (i < 9) {
    galleriaBook2017.push(`${pathBooks}2017/Book2017-0000${i + 1}.jpg`)
  } else {
    galleriaBook2017.push(`${pathBooks}2017/Book2017-000${i + 1}.jpg`)
  }
}

export const galleriaBook2018 = []
for (let i = 0; i < 30; i++) {
  if (i < 9) {
    galleriaBook2018.push(`${pathBooks}2018/Book2018-0000${i + 1}.jpg`)
  } else {
    galleriaBook2018.push(`${pathBooks}2018/Book2018-000${i + 1}.jpg`)
  }
}

export const galleriaBook2019 = []
for (let i = 0; i < 22; i++) {
  if (i < 9) {
    galleriaBook2019.push(`${pathBooks}2019/Book2019-0000${i + 1}.jpg`)
  } else {
    galleriaBook2019.push(`${pathBooks}2019/Book2019-000${i + 1}.jpg`)
  }
}

export const galleriaBooks = {
  width: 770,
  number: 0,
  galleria: [
    galleriaBook2016,
    galleriaBook2017,
    galleriaBook2018,
    galleriaBook2019,
  ],
  photoIcon: [Сover2016, Сover2017, Сover2018, Сover2019],
  header: ['Brest 2016', 'Brest 2017', 'Brest 2018', 'Brest 2019'],
}

export const galleriaReportage = []
export const galleriaReportage170 = []
for (let i = 0; i < 65; i++) {
  if (i < 9) {
    galleriaReportage.push(
      `${pathGalleris}Reportage/1000/Reportage0000${i + 1}.jpg`
    )
    galleriaReportage170.push(
      `${pathGalleris}Reportage/170/Reportage0000${i + 1}.jpg`
    )
  } else {
    galleriaReportage.push(
      `${pathGalleris}Reportage/1000/Reportage000${i + 1}.jpg`
    )
    galleriaReportage170.push(
      `${pathGalleris}Reportage/170/Reportage000${i + 1}.jpg`
    )
  }
}

export const galleriaArtistic = []
export const galleriaArtistic170 = []
for (let i = 0; i < 62; i++) {
  if (i < 9) {
    galleriaArtistic.push(
      `${pathGalleris}Artistic/1000/Artistic0000${i + 1}.jpg`
    )
    galleriaArtistic170.push(
      `${pathGalleris}Artistic/170/Artistic0000${i + 1}.jpg`
    )
  } else {
    galleriaArtistic.push(
      `${pathGalleris}Artistic/1000/Artistic000${i + 1}.jpg`
    )
    galleriaArtistic170.push(
      `${pathGalleris}Artistic/170/Artistic000${i + 1}.jpg`
    )
  }
}

export const galleriaClub = {
  // width: 740,
  number: 0,
  galleria: [galleriaClub1000],
  photoIcon: [galleriaClub170],
  // header: ['Сlub'],
}

export const galleriaPhoto = {
  // width: 740,
  number: 0,
  galleria: [
    galleriaReportage,
    galleriaArtistic,
    // galleriaGallerisReportage,
  ],
  photoIcon: [galleriaReportage170, galleriaArtistic170],
  header: ['Reportage', 'Artistic', 'Brest 2017'],
}

export const galleriaSeria2023 = []
for (let i = 0; i < 60; i++) {
  if (i < 9) {
    galleriaSeria2023.push(`${pathSeries}2023/2023seria0000${i + 1}.jpg`)
  } else {
    galleriaSeria2023.push(`${pathSeries}2023/2023seria000${i + 1}.jpg`)
  }
}

export const galleriaSeria2022 = []
for (let i = 0; i < 54; i++) {
  if (i < 9) {
    galleriaSeria2022.push(`${pathSeries}2022/2022seria0000${i + 1}.jpg`)
  } else {
    galleriaSeria2022.push(`${pathSeries}2022/2022seria000${i + 1}.jpg`)
  }
}

export const galleriaSeria2017 = []
for (let i = 0; i < 36; i++) {
  if (i < 9) {
    galleriaSeria2017.push(`${pathSeries}2017/2017seria0000${i + 1}.jpg`)
  } else {
    galleriaSeria2017.push(`${pathSeries}2017/2017seria000${i + 1}.jpg`)
  }
}

export const galleriaSeria2015 = []
for (let i = 0; i < 20; i++) {
  if (i < 9) {
    galleriaSeria2015.push(`${pathSeries}2015/2015seria0000${i + 1}.jpg`)
  } else {
    galleriaSeria2015.push(`${pathSeries}2015/2015seria000${i + 1}.jpg`)
  }
}

export const galleriaSeries = {
  width: 500,
  number: 0,
  galleria: [
    galleriaSeria2023,
    galleriaSeria2022,
    galleriaSeria2017,
    galleriaSeria2015,
  ],
  photoIcon: [seria2023, seria2022, seria2017, seria2015],
  header: ['Brest 2023', 'Poland 2022', 'Brest 2017', 'Brest 2015'],
}
