// npm start
// npm run build

import "./App.css";
import Layout from './pages/Layout/Layout'
import Home from './pages/Home/Home'
import Series from './pages/Wedding/Series/Series'
import Contact from './pages/Contact/Contact'
import Books from './pages/Wedding/Books/Books'
import Family from './pages/Family/Family'
import Club from './pages/Club/Club'
import Food from './pages/Food/Food'
import Other from './pages/Other/Other'
import Photo from './pages/Wedding/Photo/Photo'
import NoPage from './pages/NoPage/NoPage'
import MenuNew from './MenuAll/MenuNew'
import Burger from './MenuAll/Burger'
import { Routes, Route, Link } from 'react-router-dom'
import { languages, cities } from './constants/ImportPhoto'
import { Dropdown } from 'primereact/dropdown'
import React, { useState } from 'react'

function App() {
  const callback = () => {}
                const [selectedCantry, setSelectedCantry] = useState(null)
  return (
    <div>
      <div className="SelectedCantry">
        <div className="card flex justify-content-center">
          <Dropdown
            value={selectedCantry}
            onChange={(e) => {
              for (let i = 0; i < e.value.code.length; i++) {
                languages[i] = e.value.code[i]
              }
              // window.location.reload()
              setSelectedCantry(e.value)
            }}
            options={cities}
            optionLabel="name"
            placeholder={'EN'}
            className="w-full md:w-14rem"
          />
        </div>
      </div>
      <div className="menu">
        <Link className="myName" to="/">
          {languages[0]}
        </Link>
        <Burger lang={languages} />
        <div className="menuBig">
          <MenuNew lang={languages} callback={callback} />
        </div>
      </div>
      <Routes>
        Family family
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="series" element={<Series />} />
          <Route path="contact" element={<Contact />} />
          <Route path="books" element={<Books />} />
          <Route path="family" element={<Family />} />
          <Route path="club" element={<Club />} />
          <Route path="food" element={<Food />} />
          <Route path="other" element={<Other />} />
          <Route path="photo" element={<Photo lang={languages} />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App;
