import React, { useState } from 'react'
import { Sidebar } from 'primereact/sidebar'
import { Button } from 'primereact/button'
import MenuNew from './MenuNew'
import './Menu.css'

export default function Burger(props) {
  var OnOff =false
  const [visibleBurger, setVisibleBurger] = useState(OnOff)
  const onOffBurger = (OnOff) => {
    setVisibleBurger(OnOff)
  }
  return (
    <div className="card">
      <Sidebar
        className="SidebarBurger"
        visible={visibleBurger}
        position="right"
        onHide={() => setVisibleBurger(false)}
      >
        <h2> </h2>
        <MenuNew lang={props.lang} callback={onOffBurger} />
      </Sidebar>
      <Button
        className="Button"
        icon="pi pi-times"
        onClick={() => setVisibleBurger(true)}
      >
        <div className="ButtonBurger"></div>
        <div className="ButtonBurger"></div>
        <div className="ButtonBurger"></div>
      </Button>
    </div>
  )
}
