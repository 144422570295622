import './utils.css'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css'

export default function PhotoGalleria(props) {
  return (
    // <div className="Galleri">
    <PhotoProvider>
      <div>
        {props.galleria.map((item, index) => (
          <PhotoView key={index} src={item}>
            <img
              src={props.photoIcon[index]}
              style={{ margin: '0px 2px' }}
              alt=""
            />
          </PhotoView>
        ))}
      </div>
    </PhotoProvider>
    // </div>
  )
}
