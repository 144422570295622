import './Club.css'
import PhotoGalleria from '../../utils/PhotoGalleria'
import { galleriaClub } from '../../constants/ImportPhoto'

export default function Club() {
  return (
    <div className="Club-header">
      <div className="Club-Galleria">
        <PhotoGalleria
          galleria={galleriaClub.galleria[0]}
          photoIcon={galleriaClub.photoIcon[0]}
        />
      </div>
    </div>
  )
}
