import './Contact.css'

const Contact = () => {
  const screenWidth = window.screen.width
  const screenHeight = window.screen.height
  console.log(screenWidth, screenHeight)
  return (
    <div className="Contact-header">
      {/* {screenWidth > screenHeight ? '100' : '200'} */}
      <div className="socialNetwork">
        <a href="viber://chat?number=375336747748">Viber</a>
        <a href="https://t.me/Volsvik" target="_blank">
          Telegram
        </a>

        {/* <a href="tg://resolve?domain=@Volsvik">Telegram</a>375336747748 */}
        {/* <a href="https://www.instagram.com/visiondom.pl" target="_blank">
        </a> */}
        {/* <a
          href="https://www.facebook.com/profile.php?id=100094671680263"
          target="_blank"
        ></a> */}
        <a href="tel:+375336747748">+375 (33) 6747748</a>
        <a href="mailto:rudyard47@gmail.com">rudyard47@gmail.com</a>
      </div>
    </div>
  )
}

export default Contact
