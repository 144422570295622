import './NoPage.css'

const NoPage = () => {
  return (
    <div className="NoPage-header">
      <h1>404</h1>;
    </div>
  )
}

export default NoPage;