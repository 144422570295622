import './Food.css'
import LongContent from '../../utils/LongContent'
import { galleriaBooks } from '../../constants/ImportPhoto'

export default function Food() {
  return (
    <div className="Food-header">
      <LongContent gall={galleriaBooks} />
    </div>
  )
}
