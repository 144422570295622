import './Series.css'
import LongContent from '../../../utils/LongContent'
import { galleriaSeries } from '../../../constants/ImportPhoto'

const Series = () => {
  return (
    <div className="Series-header">
      <LongContent gall={galleriaSeries} />
    </div>
  )
}

export default Series
