import './Family.css'
import LongContent from '../../utils/LongContent'
import { galleriaBooks } from '../../constants/ImportPhoto'

export default function Family() {
  return (
    <div className="Family-header">
      <LongContent gall={galleriaBooks} />
    </div>
  )
}
