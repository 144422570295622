import { Outlet } from 'react-router-dom'
import './Home.css'

const Home = () => {
  return (
    <div className="Home-header">
      {/* <Outlet /> */}
    </div>
  )
}

export default Home
