import './Other.css'
import LongContent from '../../utils/LongContent'
import { galleriaBooks } from '../../constants/ImportPhoto'

export default function Other() {
  return (
    <div className="Other-header">
      <LongContent gall={galleriaBooks} />
    </div>
  )
}
